var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("p", { staticClass: "text-grey text-left" }, [
          _vm._v("Please try another provider."),
        ]),
        _c("Hr"),
        _c(
          "div",
          [
            _c("Checkbox", {
              attrs: {
                options: _vm.options,
                reduce: function (item) {
                  return item.serviceProvider
                },
                label: "displayName",
                type: "circle",
                p: "pt-5",
              },
              on: { selected: _vm.getSelectedValue },
            }),
          ],
          1
        ),
        _c("Button", {
          staticClass: "mt-8",
          attrs: {
            width: "w-full",
            text: "Use this provider",
            loading: _vm.loading,
          },
          on: { click: _vm.done },
        }),
        _c(
          "p",
          {
            staticClass:
              "text-center cursor-pointer text-brandPurple font-semibold pt-6",
            on: {
              click: function ($event) {
                return _vm.$emit("cancel")
              },
            },
          },
          [_vm._v(" Cancel, I will add it later ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
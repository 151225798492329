<template>
  <div>
    <div>
      <p class="text-grey text-left">Please try another provider.</p>
      <Hr />
      <div>
        <Checkbox
          :options="options"
          @selected="getSelectedValue"
          :reduce="(item) => item.serviceProvider"
          label="displayName"
          type="circle"
          p="pt-5"
        />
      </div>
      <Button
        @click="done"
        width="w-full"
        text="Use this provider"
        class="mt-8"
        :loading="loading"
      />
      <p
        @click="$emit('cancel')"
        class="text-center cursor-pointer text-brandPurple font-semibold pt-6"
      >
        Cancel, I will add it later
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Button } from "@/UI/Button";
import { Checkbox } from "@/UI/Input";
import Hr from "@/UI/Hr";

export default {
  components: {
    Button,
    Hr,
    Checkbox,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data() {
    return {
      selected: ""
    }
  },
  methods: {
    ...mapActions("notification", ["showAlert"]),
    done() {
      this.$emit('selected', this.selected)
      // this.showAlert({
      //   description:
      //     "Your debit card **** 0908 could not be charged for payments. Please add another card to make future payments easier.",
      //   display: true,
      //   type: "warning",
      //   title: "We noticed something about your debit card",
      //   buttonText: "Add another card",
      //   align: "left",
      //   callback: () => this.addCard(),
      //   secondaryCallback: () => this.$emit("cancel"),
      // });
    },
    getSelectedValue(e) {
      this.selected = e;
    }
  },
};
</script>

<style>
</style>